$(document).ready(function () {


  // media queries
  const
    rootStyles = getComputedStyle(document.body),
    mq = [
      window.matchMedia("(max-width: " + rootStyles.getPropertyValue("--mobile-max") + ")"),
      window.matchMedia("(min-width: " + rootStyles.getPropertyValue("--tablet-min") + ") and (max-width: " + rootStyles.getPropertyValue("--tablet-max") + ")"),
      window.matchMedia("(min-width: " + rootStyles.getPropertyValue("--desktop-min") + ")")
    ];


  // resize events
  let current;

  $(window).on("resize", function () {
    // positioning elements
    if (current !== "mobile" && mq[0].matches) {

      $(".js-pos-header-body-right").insertAfter($(".js-pos-header-body-mid"));
      $(".js-pos-site-search").insertAfter($(".js-pos-header-body-right"));
      $(".js-pos-site-headlines").insertBefore($(".js-pos-products-gallery"));
      current = "mobile";

    } else if (current !== "tablet" && mq[1].matches) {

      $(".js-pos-header-body-right").insertBefore($(".js-pos-header-body-row-search"));
      $(".js-pos-site-search").prependTo($(".js-pos-header-body-row-search"));
      $(".js-pos-site-headlines").prependTo($(".js-pos-products-desc"));
      current = "tablet";

    } else if (current !== "desktop" && mq[2].matches) {

      $(".js-pos-header-body-right").insertAfter($(".js-pos-header-body-mid"));
      $(".js-pos-site-search").prependTo($(".js-pos-header-body-row-search"));
      $(".js-pos-site-headlines").prependTo($(".js-pos-products-desc"));
      current = "desktop";

    }
  }).trigger("resize");


  // drop open and hide when click outside
  const
    links = ".js-dropdown-link",
    innerElements = ".js-dropdown";

  $(document).on("click", links, function (e) {
    if ($(this).hasClass("active")) {
      $(links).filter('a[href="' + this.hash + '"]').removeClass("active");
      $(innerElements).filter(this.hash).removeClass("open");
    } else {
      $(links).removeClass("active").filter('a[href="' + this.hash + '"]').addClass("active");
      $(innerElements).removeClass("open").filter(this.hash).addClass("open");
      $(links).add(innerElements).off();
    }
    e.preventDefault();
  }).on("mouseup touchstart keyup", function (e) {
    if ((!$(links).add(innerElements).is(e.target) && $(links).add(innerElements).has(e.target).length === 0) || e.keyCode === 27) {
      $(links).removeClass("active");
      $(innerElements).removeClass("open");
    }
  });


  // aside elements toggle
  const
    linksSiteToggles = ".js-site-toggles",
    innerSiteTogglesBlock = ".js-site-toggles-block";

  $(document).on("click", linksSiteToggles, function (e) {
    if ($(this).hasClass("active")) {
      $(linksSiteToggles).filter('a[href="' + this.hash + '"]').removeClass("active");
      $(innerSiteTogglesBlock).filter(this.hash).slideUp();
    } else {
      $(linksSiteToggles).removeClass("active").filter('a[href="' + this.hash + '"]').addClass("active");
      $(innerSiteTogglesBlock).slideUp().filter(this.hash).slideDown();
    }
    e.preventDefault();
  });


  // header info remove
  $(document).on("click", ".js-header-info-remove", function (e) {
    $(".js-header-info").slideUp(function name(params) {
      $(this).remove();
    });
    e.preventDefault();
  });


  // nav-inner sub
  $(document).on("click", ".js-nav-inner-links-drop", function (e) {
    if ($(this).next(".js-nav-inner-last").length) {
      $(this).toggleClass("active");
      $(this).next().slideToggle(function () {
        $(this).toggleClass("open");
      });
      e.preventDefault();
    }
  });


  // sliders
  var swiper1 = new Swiper(".js-hero-slider", { // eslint-disable-line
    effect: "fade",
    autoplay: {
      delay: 3500,
      pauseOnMouseEnter: true,
      disableOnInteraction: false
    },
    pagination: {
      el: ".js-hero-slider .swiper-pagination",
      clickable: true
    }
  });

  var swiper2 = new Swiper(".js-cat-slider", { // eslint-disable-line
    slidesPerView: "auto",
    spaceBetween: 15,
    navigation: {
      nextEl: ".js-cat-slider-next",
      prevEl: ".js-cat-slider-prev",
    },
    breakpoints: {
      768: {
        spaceBetween: 20
      }
    }
  });

  var swiper3 = new Swiper(".js-partners-slider", { // eslint-disable-line
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      450: {
        slidesPerView: 2
      },
      680: {
        slidesPerView: 3
      },
      1000: {
        slidesPerView: 4
      },
      1400: {
        slidesPerView: 5
      },
      1600: {
        slidesPerView: 6
      }
    }
  });

  var swiper4 = new Swiper(".js-products-viewed", { // eslint-disable-line
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      el: ".js-products-viewed .swiper-pagination",
      clickable: true
    },
    breakpoints: {
      650: {
        slidesPerView: 2
      },
      1000: {
        slidesPerView: 3
      },
      1400: {
        slidesPerView: 4
      },
      1770: {
        slidesPerView: 5
      }
    }
  });

  var swiper5 = new Swiper(".js-products-gallery-thumb", { // eslint-disable-line
    spaceBetween: 10,
    slidesPerView: "auto",
    centeredSlides: true,
    centeredSlidesBounds: true,
    centerInsufficientSlides: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: ".js-products-gallery-thumb-next",
      prevEl: ".js-products-gallery-thumb-prev"
    }
  });
  var swiper6 = new Swiper(".js-products-gallery-main", { // eslint-disable-line
    loop: true,
    watchSlidesProgress: true,
    thumbs: {
      swiper: swiper5,
      autoScrollOffset: 1
    }
  });

  var swiper7 = new Swiper(".js-products-related", { // eslint-disable-line
    slidesPerView: 2,
    spaceBetween: 3,
    pagination: {
      el: ".js-products-related .swiper-pagination",
      clickable: true
    },
    breakpoints: {
      550: {
        slidesPerView: 3
      },
      800: {
        slidesPerView: 4
      },
      1150: {
        slidesPerView: 5
      },
      1400: {
        slidesPerView: 6
      },
      1770: {
        slidesPerView: 7
      }
    }
  });


  // custom select
  if ($.fn.select2) {
    $(".js-select").select2({
      minimumResultsForSearch: Infinity
    });
  }


  // equal height
  if ($.fn.matchHeight) {
    $(".js-equal").matchHeight({
      byRow: true,
      property: "min-height",
      target: null,
      remove: false
    });
  }


  // range slider
  if ($.fn.ionRangeSlider) {

    var $rangePrice = $(".js-range-price-slider"),
      $inputPriceFrom = $(".js-range-price-slider-input-ftom"),
      $inputPriceTo = $(".js-range-price-slider-input-to"),
      instancePrice,
      minPrice = 0,
      maxPrice = 4000,
      fromPrice = 0,
      toPrice = 0;

    var updatePriceInputs = function (data) {
      fromPrice = data.from;
      toPrice = data.to;

      $inputPriceFrom.prop("value", fromPrice);
      $inputPriceTo.prop("value", toPrice);
    }

    $rangePrice.ionRangeSlider({
      skin: "round",
      type: "double",
      min: minPrice,
      max: maxPrice,
      from: 80,
      to: 3000,
      onStart: updatePriceInputs,
      onChange: updatePriceInputs
    });

    instancePrice = $rangePrice.data("ionRangeSlider");

    $inputPriceFrom.on("input", function () {
      var val = $(this).prop("value");

      // validate
      if (val < minPrice) {
        val = minPrice;
      } else if (val > toPrice) {
        val = toPrice;
      }

      instancePrice.update({
        from: val
      });
    });

    $inputPriceTo.on("input", function () {
      var val = $(this).prop("value");

      // validate
      if (val < fromPrice) {
        val = fromPrice;
      } else if (val > maxPrice) {
        val = maxPrice;
      }

      instancePrice.update({
        to: val
      });

    });

  }


  // -+ number
  $(document).on("click", ".js-minus, .js-plus", function (e) {
    e.preventDefault();
    const qty = $(this).siblings(".js-qty-input");
    if ($(this).hasClass("js-minus") && qty.length) {
      let amount = qty.val();
      if (amount.length && parseInt(amount) > 1) {
        qty.val(parseInt(amount) - 1);
      }
    }
    if ($(this).hasClass("js-plus") && qty.length) {
      let amount = qty.val();
      if (amount.length) {
        qty.val(parseInt(amount) + 1);
      }
    }
    return false;
  });


});
